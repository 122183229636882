import { MultiFamilyProgram } from './community-information';

export type AdditionalService =
  | 'Education'
  | 'Technical Support'
  | 'Enforcement'
  | 'Ordinance';

export type MultifamilyAdditionalService = AdditionalService;

export const MULTI_FAMILY_ADDITIONAL_SERVICES: MultifamilyAdditionalService[] =
  ['Education', 'Technical Support', 'Enforcement', 'Ordinance'];

export const MULTI_FAMILY_ADDITIONAL_SERVICES_CONTROLS: (keyof MultiFamilyProgram)[] =
  ['hasEducation', 'hasTechnicalSupport', 'hasEnforcement', 'hasOrdinance'];

// PSO: be aware we assume MULTI_FAMILY_ADDITIONAL_SERVICES_CONTROLS and MULTI_FAMILY_ADDITIONAL_SERVICES are listed in the same orders....

export const convertAdditionalServiceToObject = (
  additionalServices: MultifamilyAdditionalService[]
): any => {
  // determines boolean values from string array
  const result: any = {};
  MULTI_FAMILY_ADDITIONAL_SERVICES_CONTROLS.forEach(
    (key, index) =>
      (result[key] = additionalServices.includes(
        MULTI_FAMILY_ADDITIONAL_SERVICES[index]
      ))
  );
  return result;
};

export const convertAdditionalServiceToArray = (
  booleansObject: any
): MultifamilyAdditionalService[] => {
  // determines string array from object containing booleans
  const result: any = [];
  if (!booleansObject) {
    return result;
  }
  MULTI_FAMILY_ADDITIONAL_SERVICES_CONTROLS.forEach((key, index) => {
    if (booleansObject[key]) {
      result.push(MULTI_FAMILY_ADDITIONAL_SERVICES[index]);
    }
  });
  return result;
};
