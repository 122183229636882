export type ParticipationRateMethod =
  | 'On-truck technology'
  | 'Periodic route audits'
  | 'Extrapolated from set-out rates'
  | 'Participation is estimated but not measured';

export const PARTICIPATION_RATE_METHODS: ParticipationRateMethod[] = [
  'On-truck technology',
  'Periodic route audits',
  'Extrapolated from set-out rates',
  'Participation is estimated but not measured',
];
