export type ContaminationRateMethod =
  | 'Provided by MRF operator'
  | 'Provided by hauler'
  | 'Determined by periodic detailed audit of collected materials'
  | 'Determined by periodic audit of recyclables performed at multifamily properties'
  | 'On-truck visioning technology'
  | 'Driver or inspector estimate'
  | 'Driver/inspector/site attendant estimate'
  | 'Other';

export const SINGLE_FAMILY_CONTAMINATION_RATE_METHODS: ContaminationRateMethod[] =
  [
    'Provided by MRF operator',
    'Determined by periodic detailed audit of collected materials',
    'On-truck visioning technology',
    'Driver or inspector estimate',
    'Other',
  ];

export const MULTI_FAMILY_CONTAMINATION_RATE_METHODS: ContaminationRateMethod[] =
  [
    'Provided by MRF operator',
    'Provided by hauler',
    'Determined by periodic detailed audit of collected materials',
    'Determined by periodic audit of recyclables performed at multifamily properties',
    'On-truck visioning technology',
    'Driver or inspector estimate',
    'Other',
  ];

export const DROP_OFF_CONTAMINATION_RATE_METHODS: ContaminationRateMethod[] = [
  'Provided by MRF operator',
  'Provided by hauler',
  'Determined by periodic detailed audit of collected materials',
  'Driver/inspector/site attendant estimate',
  'Other',
];
