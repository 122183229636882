export type ContaminationControl =
  | 'Driver (route-based) inspection'
  | 'Direct Mail or Bill Inserts'
  | 'Cart Tagging'
  | 'Cart Rejections'
  | 'Municipality provides education'
  | 'General Advertisements'
  | 'Oops card or other kinds of cart-tagging'
  | 'Rejection of contaminated receptacles'
  | 'Regular cart inspections'
  | 'Encouragement card or other educational mailers'
  | 'Other';

export const CONTAMINATION_CONTROLS: ContaminationControl[] = [
  'Driver (route-based) inspection',
  'Direct Mail or Bill Inserts',
  'Cart Tagging',
  'Cart Rejections',
  'Municipality provides education',
  'General Advertisements',
  'Oops card or other kinds of cart-tagging',
  'Rejection of contaminated receptacles',
  'Regular cart inspections',
  'Encouragement card or other educational mailers',
  'Other',
];
