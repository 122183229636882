export type SingleFamilyContainer =
  | 'bins'
  | 'carts'
  | 'bags'
  | 'dumpsters'
  | 'other';

export const SINGLE_FAMILY_CONTAINERS: {
  value: SingleFamilyContainer;
  label: string;
}[] = [
  { value: 'bags', label: 'Bags' },
  { value: 'bins', label: 'Bins' },
  { value: 'carts', label: 'Carts' },
  { value: 'dumpsters', label: 'Dumpsters' },
  { value: 'other', label: 'Other' },
];

export type MultiFamilyContainer =
  | 'bags'
  | 'bins'
  | 'carts'
  | 'dumpsters'
  | 'compactors'
  | 'roll-off uncompacted'
  | 'varies'
  | 'other';

export const MULTI_FAMILY_CONTAINERS: {
  value: MultiFamilyContainer;
  label: string;
}[] = [
  { value: 'bags', label: 'Bags' },
  { value: 'bins', label: 'Bins' },
  { value: 'carts', label: 'Carts' },
  { value: 'dumpsters', label: 'Dumpsters' },
  { value: 'compactors', label: 'Roll-off (loose / uncompacted)' },
  { value: 'roll-off uncompacted', label: 'Roll-off (compacting)' },
  { value: 'other', label: 'Other' },
  { value: 'varies', label: 'Varies' },
];
