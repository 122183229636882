export type SingleFamilyCollectionType =
  | 'Open Market'
  | 'Automatically Provided'
  | 'Community-operated opt-in'
  | 'Community-operated opt-in (with fee)';

export type MultiFamilyCollectionType =
  | 'Open Market'
  | 'Required by Law'
  | 'Pilot'
  | 'Available from Community';

export const SINGLE_FAMILY_COLLECTION_TYPES: SingleFamilyCollectionType[] = [
  'Open Market',
  'Automatically Provided',
  'Community-operated opt-in',
  'Community-operated opt-in (with fee)',
];

export const MULTI_FAMILY_COLLECTION_TYPES: MultiFamilyCollectionType[] = [
  'Open Market',
  'Required by Law',
  'Pilot',
  'Available from Community',
];
