export type SetOutRateMethod =
  | 'Driver tracked stops served during a route (e.g. handheld clicker)'
  | 'On-truck technology'
  | 'Periodic route audits';

export const SETOUT_RATE_METHODS: SetOutRateMethod[] = [
  'Driver tracked stops served during a route (e.g. handheld clicker)',
  'On-truck technology',
  'Periodic route audits',
];
