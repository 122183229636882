export type CollectionFrequency =
  | 'Weekly'
  | 'Every Other Week'
  | 'Varies'
  | 'Other';
export const COLLECTION_FREQUENCIES: CollectionFrequency[] = [
  'Weekly',
  'Every Other Week',
  'Varies',
  'Other',
];
