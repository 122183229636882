import { CartSize } from './cart-size';
import { CollectionFrequency } from './collection-frequency';
import { CollectionHow } from './collection-how';
import {
  MultiFamilyCollectionType,
  SingleFamilyCollectionType,
} from './collection-type';
import { SingleFamilyContainer, MultiFamilyContainer } from './container';
import { ContaminationControl } from './contamination-control';
import { ContaminationRateMethod } from './contamination-rate-method';
import { SetOutRateMethod } from './setout-rate-method';
import { NDBAPIMRF } from './mrf';
import { ParticipationRateMethod } from './participation-rate-method';
import { RecyclingCategory } from './recycling-category';
import { SimilarCommunity } from './similar-community';
import { YearType } from './year-type';

export function hasProgram(communityInformation: PublicCommunityInformation) {
  return (
    communityInformation.hasProgramDropoff ||
    communityInformation.hasProgramMultiFamily ||
    communityInformation.hasProgramSingleFamily
  );
}

export type PrivateCommunityInformation = CommunityInformation & {
  visibility: 'private';
  singleFamilyTonnage: number;
  multiFamilyTonnage: number;
  dropoffTonnage: number;
  numberOfFullTimeStaff?: number | undefined;
  outreachBudgetPerHouseHold?: number | undefined;
};

export type PublicCommunityInformation = {
  visibility: 'public';
} & Omit<
  CommunityInformation,
  // PSO: investigate this for now I have just added 'contaminationRateHow'
  // TODO: separate public and private properties (inside the programs)
  // | 'singleFamilyPrograms'
  // | 'multiFamilyPrograms'
  // | 'dropoffPrograms'

  | 'householdsServedSingleFamily'
  | 'householdsServedMultiFamily'
  | 'hasMultiFamilyEducation'
  | 'hasMultiFamilyTechnicalSupport'
  | 'hasMultiFamilyEnforcement'
  | 'poundsPerHousehold'
  | 'totalTonnage'
  | 'singleFamilyTonnage'
  | 'multiFamilyTonnage'
  | 'dropoffTonnage'
  | 'contaminationRatePercentage'
  | 'contaminationRateHow'
  | 'setOutRatePercentage'
  | 'metricTonsCO2AvoidedPerYear'
  | 'contaminationControls'
  | 'outreachBudgetPerHouseHold'
>;

export type AccessType =
  | 'Curbside'
  | 'Subscription'
  | 'Dropoff'
  | 'None'
  | null;
export const ACCESS_TYPES: AccessType[] = [
  'Curbside',
  'Dropoff',
  'Subscription',
  'None',
];
export type ProgramType = 'Single-Family' | 'Multifamily' | 'Drop-Off';

export interface MetaCommunityInformation {
  communityId: string;
  updatedAtUtc: string;
}
export interface CommunityInformation extends UpdatableCommunityInformation {
  communityId: string;
  name: string;
  fullDescriptiveName: string;
  communityType: string;
  counties: Array<{
    id: string;
    name: string;
  }>;
  county: string;
  gallonsWaterSavedPerYear: number;
  incomeMean: number;
  metricTonsCO2AvoidedPerYear: number;
  occupiedHousingUnits: number;
  percentBachelorsOrHigher?: number;
  percentRaceBipoc?: number;
  programType: ProgramType[] | null | undefined;
  similarCommunities?: SimilarCommunity[];
  state: {
    id: string;
    name: string;
    two_letter_code: string;
  };
  tonsSavedPerYear: number;
  totalHousingUnits: number;
  totalPopulation: number;
  accessType: AccessType;
  suffix: string;
  updatedAtUtc: string;
}

export interface ProgramSharedProperties {
  id: number;
  // REFACTOR: move this to the submission parent
  programId: number;
  materialsAccepted: Array<RecyclingCategory['id']>;
  // represent temporary frontend state that can be restored
  softProgramTerminated?: boolean;
  softProgramAdded?: boolean;
  programTerminated?: boolean;
  poundsPerHouseholdYear?: number;
  poundsPerHouseholdYearType?: YearType;
  poundsPerHouseholdReportingPeriodStart?: any;
  poundsPerHouseholdReportingPeriodEnd?: any;
  tonnage?: number;
  householdsServed?: number;
  poundsPerHousehold?: number;
  contaminationRatePercentage?: number;
  contaminationRateHow?: ContaminationRateMethod[];
  measurementYear?: number;
}

export interface SingleFamilyProgram extends ProgramSharedProperties {
  collectionType?: SingleFamilyCollectionType;
  collectionHow?: CollectionHow;
  collectionFrequency?: CollectionFrequency;
  containerTypes?: SingleFamilyContainer[];
  cartSizes: CartSize[];
  contaminationControls?: ContaminationControl[];
  setOutRatePercentage?: number;
  participationRatePercentage?: number;
  participationRateHow?: ParticipationRateMethod[];
  setOutRateHow?: SetOutRateMethod[];
}
export interface MultiFamilyProgram extends ProgramSharedProperties {
  collectionType?: MultiFamilyCollectionType;
  additionalServices?: string[];
  hasOrdinance?: boolean;
  hasEducation?: boolean;
  hasTechnicalSupport?: boolean;
  hasEnforcement?: boolean;
  containerTypes?: [];
  collectionFrequency?: CollectionFrequency;
  setOutRatePercentage?: number;
  numberOfProperties?: number;
  numberOfPropertiesWithAccess?: number;
  ordinanceUrl?: string;
  participationRatePercentage?: number;
  participationRateHow?: ParticipationRateMethod[];
}

export type DropoffProgram = ProgramSharedProperties;

export type AnyRecyclingProgram =
  | SingleFamilyProgram
  | MultiFamilyProgram
  | DropoffProgram;

export interface SingleFamilyProgramWithType extends SingleFamilyProgram {
  type: 'singleFamilyPrograms';
}

export interface MultiFamilyProgramWithType extends MultiFamilyProgram {
  type: 'multiFamilyPrograms';
}

export interface DropoffProgramWithType extends DropoffProgram {
  type: 'dropoffPrograms';
}

export type CommunityProgramWithType =
  | SingleFamilyProgramWithType
  | MultiFamilyProgramWithType
  | DropoffProgramWithType;

export interface UpdatableCommunityInformation {
  hasProgramDropoff: boolean;
  hasProgramMultiFamily: boolean;
  hasProgramSingleFamily: boolean;
  hasMultiFamilyOrdinance: boolean;
  contaminationControls?: ContaminationControl[];
  contaminationRatePercentage?: number;
  guidelinesWebsite?: string;

  multiFamilyCollectionFrequency?: CollectionFrequency;
  onlineSearchUrl?: string;
  outreachBudgetPerHouseHold?: number;
  mrfs?: Array<NDBAPIMRF>;

  setOutRatePercentage?: number;

  // educationAndOutreachFte?: number;
  numberOfFullTimeStaff?: number;
  totalTonnage?: number;
  occupancyRate?: number;

  poundsPerHousehold?: number;

  singleFamilyPrograms?: SingleFamilyProgram[];
  multiFamilyPrograms?: MultiFamilyProgram[];
  dropoffPrograms?: DropoffProgram[];
}

export interface ZipCommunity {
  communityId: string;
  communityName: string;
  communityNamePublic: string;
  zipCode: string;
  state: string;
}
