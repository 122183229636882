export type CollectionHow =
  | 'No fee subscription'
  | 'Automatic'
  | 'Fee subscription'
  | 'Other';

export const COLLECTION_HOWS: CollectionHow[] = [
  'No fee subscription',
  'Automatic',
  'Fee subscription',
  'Other',
];
